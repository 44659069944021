/* Services.css */

.service-contaner {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    margin: 0px auto;
    border-radius: 18px;
    width: fit-content;
    height: fit-content;
  }
  

  .service-text {
    color: white;
    background-color: #18181a;
    /* background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%);
 background-blend-mode: multiply; */
    border-radius: 18px;
    padding: 15px 15px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);
    height: 40%;
    max-width: 800px;
  }
  
  .service-web, .service-app {
    border-radius: 18px;
    margin: 10px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);
    background: #fff;
  }

  .service-app a{
    text-decoration: none;
  }
  .service-web a{
    text-decoration: none;
  }
  
  .service-left div {
    padding: 10px;
    min-width: 180px;
    text-align: center;
  }

  .service-web a,  .service-app a{
    color: #000;
  }
  
  .service-left {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .service-right{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0px;
    padding-right: 10px;
  }
  .how-it-works {
    text-align: center;
    /* background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%); */
    background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%);
    padding: 20px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);
    border-radius: 18px;
    margin-top: 10px;
    max-width: 800px;
  }
  
  .steps {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .step {
    width: 30%;
    text-align: center;
  }
  
  .icon {
    font-size: 50px;
    margin-bottom: 10px;
    color: #000; /* Adjust color as needed */
  }
  
  .step h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .step p {
    font-size: 0.9em;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .service-contaner {
      display: flex;
      flex-direction: column-reverse;
      margin: 0px 10px;
      border-radius: 18px;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);

    }
    
    .how-it-works{
        display: none;
    }
    .service-left {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      background: white;
      border-radius: 0px 0px 18px 18px;
    }
    
    .service-text{
      border-radius: 18px 18px 0px 0px;

    }
    .service-left div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 200px;
    }
  
    .service-right {
      /* color: white; */
      flex-direction: column-reverse;
      margin: 0px;
      padding-right: 0px;
    }
  
    .service-contaner {
      margin-top: 50px;
    }
  
    .service-web, .service-app {
      border-radius: 18px;
      margin: 0px;
      box-shadow: none;
    }

    .steps {
        flex-wrap: wrap;
        width: 100%;
    }
    .step{
        width: 180px;
    }
  }
  