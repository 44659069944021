.nav-absolute {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    border-radius: 16px;
    margin: 0px 10px;
    
    background: #18181a;
    box-shadow: 0px 11px 10px rgba(0, 0, 0, 0.2);

    color: white;
    padding: 10px;
    height: 48px;

    position: absolute;
    top: 5px;
    left: 0px;
    width: -webkit-fill-available;
    z-index: 100;
}

.logo{
    height: 25px;
    width: auto;
    margin: auto 0px;
}
.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    background: #18181a;
    box-shadow: 0px 11px 10px rgba(0, 0, 0, 0.2);

    color: white;
    padding: 10px;
    height: 48px;
    z-index: 100;
    width: -webkit-fill-available;
}

.view{
    display: none;
}

.left-nav {
    padding-left: 20px; 
    display: flex;
    flex-direction: row;
    align-items: center;
}
.nav-name{
    font-size: 25px;
    font-weight: 600;
    margin-left: 8px;
    font-family: 'outfit', sans-serif;
}

.right-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
}

.right-nav a {
    color: white;
    text-decoration: none;
    margin-right: 25px; 
    font-size: 18px;
    font-weight: 400;
}

.right-nav a:hover{
    background:linear-gradient(8deg, #ec008c, #fc6767);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.sandwich-button {
    display: none; 
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .right-nav {
        display: none; 
    }

    .right-nav.show {
        display: flex; 
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        background-color: #333333f1;
        width: -webkit-fill-available;
        top: 70px;
        left: 0;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        padding: 20px 0px;
        height: 250px;
        border-radius: 16px;
        margin: 0px 10px;
    }

    .right-nav.show a{
        font-size: large;
    }
    .sandwich-button {
        display: block; 
    }
    .nav-name{
        display: none;
    }
}
