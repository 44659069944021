/* AppService.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.app-service-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f4f4f4;
    font-family: 'Roboto', sans-serif;
    /* overflow-x: hidden; */
}

.plans-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.plan-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    padding: 25px;
    text-align: center;
    max-width: 280px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-width: 300px;
}

.plan-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.plan-title {
    margin-bottom: 15px;
    color: #333;
    font-weight: 700;
    text-align: right;
    font-size: 1.4em;
    font-weight: bolder;
}

.plan-card.pop .plan-title{
    color: white;
}

.plan-card p {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.plan-card ul {
    list-style-type: none;
    padding: 0;
}

.plan-card li {
    margin: 12px 0;
    color: #444;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.plan-card li .icon {
    margin-right: 8px;
    font-size: 1.2em; /* Adjust the size of the icons here */
}

.plan-card.pop {
    border: none;
    /* background: #212121; */
    color: white;

    background-image: linear-gradient(to right, #434343 0%, black 100%);
}

.plan-card.pop h2, .plan-card.pop p, .plan-card.pop li {
    color: white;
}

.choose-plan-button {

    background: -webkit-linear-gradient(8deg, #ec008c, #fc6767);    border: none;

    border-radius: 10px;
    color: white;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 20px;
}

.plan-card.pop button{
    background: none;
    color: #ec008c;
    border: 1px solid #ec008c;
}

.contact-form .form-group {
    margin-bottom: 20px;
}

.contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
    width: -webkit-fill-available;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    color: #333;
}

.contact-form input:focus,
.contact-form textarea:focus,
.contact-form select:focus {
    border-color: #ff5722;
    outline: none;
}

/* .contact-form .form-btn {
    background: linear-gradient(to right, #ff5722, #ff9800);
    border: none;
    border-radius: 25px;
    color: white;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    transition: background 0.3s ease;
}

.contact-form .form-btn:hover {
    background: linear-gradient(to right, #ff9800, #ff5722);
} */

.contact-sections{
    background-color: white;
    border-radius: 12px;
    padding: 30px;
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.2);
}
.contact-content{
    width: -webkit-fill-available;
    margin-top: 40px;

}

@media (max-width: 768px){
    .contact-sections{
        width: 95%;
    }
    .contact-content{
        padding: 20px;
    }
}

@media (max-width: 375px){
    .plan-card{
        min-width: 200px;
    }
}