.form-plan {
    width: -webkit-fill-available;
    padding: 20px;
    /* border: 1px solid #ddd; */
    /* border-radius: 8px; */
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form div {
    margin-bottom: 15px;
}

label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 6px;
}

input[type="text"],
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

input[type="checkbox"] {
    margin-left: 10px;
    transform: scale(1.2);
}

button[type="button"],
button[type="submit"] {
    margin-right: 10px;
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button[type="button"] {
    background-color: #007bff;
    color: #fff;
}

button[type="submit"] {
    background-color: #28a745;
    color: #fff;
}

button[type="button"]:hover {
    background-color: #0056b3;
}

button[type="submit"]:hover {
    background-color: #218838;
}

button[type="button"]:focus,
button[type="submit"]:focus {
    outline: none;
}

button[type="button"]:last-of-type {
    background-color: #dc3545;
    color: #fff;
}

button[type="button"]:last-of-type:hover {
    background-color: #c82333;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.feature-item input[type="text"] {
    flex: 1;
}
