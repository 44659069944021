.bottom-bar {
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .bottom-bar-link {
    color: #a7a7a7;
    text-decoration: none;
    margin: 0 15px;
    font-weight: 500;
    transition: color 0.3s;
  }
  
  .bottom-bar-link:hover {
    color: #ffffff;
  }
  