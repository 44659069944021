body{
    margin: 0;
    box-sizing: border-box;
    font-family: 'outfit', sans-serif;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    /* align-items: center; */

    background-color: #fafafa;
    background-image: radial-gradient(#000000 0.5px, #fafafa 0.5px);
    background-size: 10px 10px;
}
*{
    scroll-behavior: smooth;
}