.console-container {
    display: flex;
    height: 100vh;
    background-color: #f4f4f4;
}

.left-sidebar {
    width: 250px;
    background-color: #ffffff;
    border-right: 1px solid #ccc;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.left-sidebar > div:first-child {
    font-size: 24px;
    font-weight: bold;
    color: #4caf50;
    margin-bottom: 20px;
}

.left-sidebar-options {
    display: flex;
    flex-direction: column;
}

.left-sidebar-options > div {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.left-sidebar-options > div:hover {
    background-color: #e8f5e9;
    border-radius: 4px;
}

.right-sidebar {
    flex-grow: 1;
    overflow-y: auto;
}

.right-sidebar h2 {
    color: #4caf50;
    margin-top: 0;
}

@media (max-width: 768px) {
    .console-container {
        flex-direction: column;
        height: auto;
    }

    .left-sidebar {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #ccc;
    }

    .right-sidebar {
        width: 100%;
    }

    .left-sidebar > div:first-child {
        font-size: 20px;
    }

    .left-sidebar-options > div {
        padding: 10px;
        text-align: center;
    }
}

.menu-toggle-button  {
    display: none;
    position: absolute;
    top: 20px;
    left: 20px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .menu-toggle-button  {
        display: block;
    }

    .left-sidebar {
        display: none;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background-color: white;
        z-index: 1000;
    }

    .left-sidebar.visible {
        display: block;
    }

    .right-sidebar {
        margin-top: 60px;
    }
}
