.contact-top-text {
  font-size: 40px;
  font-weight: 900;

  margin-bottom: 10px;
  text-align: center;

  background: -webkit-linear-gradient(8deg, #ec008c, #fc6767);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
}

.contact-image {
  width: 400px;
  height: 400px;
}

.contact-image img {
  width: 100%;
  height: 100%;
}

.contact-section {
  padding: 20px;
  /* max-width: 500px; */
  width: 300px;
  /* margin: auto; */
  font-family: Arial, sans-serif;
  border: 1px solid lightgray;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  border-radius: 8px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  /* padding: 10px; */
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
  height: 150px;
}

.form-btn {
  padding: 10px 20px;

  background-image: linear-gradient(8deg, #ec008c, #fc6767);

  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}

.form-btn:hover {
  background-image: linear-gradient(8deg, #ec008c, #fc6767);
}


@media (max-width: 768px) {
  .contact-image {
    display: none;
  }

  .contact-section {
    width: 100%;
    box-shadow: none;
    background: #f9f9f9;
    border: 1px solid #ccc;
    margin: 5px;
  }
}