/* src/FAQ.css */
.faq-section {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.faq-top-text {
  font-size: 40px;
  font-weight: 900;

  /* margin-bottom: 10px; */
  text-align: center;

  background: -webkit-linear-gradient(8deg, #ec008c, #fc6767);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faq-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.faq-item {
  flex: 1 1 calc(50% - 20px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 20px;
  transition: transform 0.3s ease;
  cursor: pointer;

  background-color: #fff;
}

.faq-bottom-text{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-top: 40px;
}

.faq-item.active {
  transform: translateY(-5px);
}

.faq-question {
  font-weight: bold;
  transition: color 0.3s ease;
}

.faq-answer {
  margin-top: 10px;
  color: #555;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.faq-item.active {
  background-image: linear-gradient(to top, #f77062 0%, #fe5196 100%);
}
.faq-item.active .faq-question {
  color: white;
}

.faq-item.active .faq-answer {
  max-height: 400px;
  color: white;
}

.arrow-down-centered{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}