.why-choose-contaner{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    padding: 40px 10px;
    margin-bottom: 40px;
    /* background-color: #0c0c0c; */
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    color: white;
}

.top-text{
    font-size: 40px;
    font-weight: 900;

    /* margin-bottom: 10px; */
    text-align: center;

    background: -webkit-linear-gradient(8deg, #ec008c, #fc6767);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.left-container-choose{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.right-container-choose{
    font-size: 16px;
    font-weight: 500;
}
.text-choose{
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    margin-bottom: 20px;

    background: -webkit-linear-gradient(8deg, #ec008c, #fc6767);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.right-container-choose{
    width: 60%;
}

@media (max-width: 768px) {
    .why-choose-contaner{
        flex-direction: column;
    }

    .right-container-choose{
        width: 100%;
        margin-bottom: 20px;
    }

    .left-container-choose{
        width: 100%;
    }
}