/* Plan list container */
.plan-list-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* max-width: 800px; */
    margin: 10px 10px;
    font-family: 'Arial', sans-serif;
}

/* Plan list header */
.plan-list-header {
    font-size: 24px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Plan list item */
.plan-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
}

/* Plan title */
.plan-item h3 {
    font-size: 20px;
    color: #333;
    margin: 0;
    flex-grow: 1;
}

/* Display plan position */
.plan-position {
    font-size: 16px;
    color: #4caf50;
    margin-right: 20px;
}

/* Move buttons section */
.move-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.move-btns button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
    font-size: 18px;
    color: #4caf50;
    transition: color 0.2s ease;
}

.move-btns button:hover {
    color: #8bc34a;
}

.move-btns button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

/* Delete button */
.delete-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
    font-size: 18px;
    color: #f44336;
    transition: color 0.2s ease;
}

.delete-btn:hover {
    color: #d32f2f;
}

/* Collapsible button */
.collapsible-btn {
    background-color: #4caf50;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease;
}

.collapsible-btn:hover {
    background-color: #388e3c;
}

/* Collapsible content */
.plan-item .collapsible-content {
    display: none;
    margin-top: 10px;
    padding-left: 20px;
}

.plan-item .collapsible-content.active {
    display: block;
}

/* Features list within plan */
.plan-feature-item {
    list-style-type: square;
    margin-bottom: 5px;
    color: #555;
}

/* Hover effect on plan items */
.plan-item:hover {
    background-color: #f1f1f1;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .plan-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .plan-position {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .move-btns {
        margin-top: 10px;
    }
}
