.back-container{
  height: 100svh;
  width: fit-content;
}
.main-body{
  /* background-color: #fafafa;
  background-image: radial-gradient(#000000 0.5px, #fafafa 0.5px);
  background-size: 10px 10px; */
}
.Hero-contaner {
  position: relative;
  height: 100svh;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  flex-direction: row-reverse;

  background: rgb(2,0,36);
  background: linear-gradient(128deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 53%, rgba(255,255,255,1) 100%);
}


.btn-txt {
  text-align: left;
  width: fit-content;
  height: fit-content;
  padding: 8px 10px;
  border-radius: 8px;
  background: -webkit-linear-gradient(8deg, #ec008c, #fc6767);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 2px solid #ec008c;
  cursor: pointer;
}

.Hero-image {
  width: 30vw;
  height: 30vw;
  background-color: rgb(255, 255, 255);
}

.Hero-text {
  font-family: "Rubik", sans-serif;
  font-size: 4vw;
  font-weight: 900;
  font-style: normal;
  width: 40%;
  z-index: 1;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.hero-img {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  mix-blend-mode: multiply;

}

.scroll-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 19px;
  font-weight: bold;
  color: #2e2e2ed7;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-arrow {
  margin-top: 5px;
  animation: bounce 2s infinite;
}

.Hero-text-desc {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  width: 84%;
}

.Hero-text-cont {
  width: 60%;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}


@media (max-width: 768px) {
  .Hero-image {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100svh;
    width: -webkit-fill-available;
  }

  .Hero-contaner {
    background-color: aqua;
    height: 100svh;
    display: flex;
    flex-direction: row;
    justify-content: center;

    padding: 0px 0px;
  }

  .Hero-text {
    font-family: "Outfit", sans-serif;
    font-size: 40px;
    font-weight: 800;
    font-style: normal;


    /* text-align: center;
    position: relative;
    bottom: -24vh;
    left: 0;
    margin: 0px auto; */
  }

  .Hero-text-desc {
    display: none;
  }

  .Hero-image {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    overflow: hidden;

  }

  .Hero-text-cont {
    position: absolute;
    bottom: 30px;
    text-align: center;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }

  .hero-img {
    width: -webkit-fill-available;
    height: auto;
    position: relative;
    top: -6vh;
    left: 0px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .btn-sec a {
    display: none;
  }

  .btn-sec {
    height: 20px;
  }

}